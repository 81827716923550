import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
// import PageTransition from "gatsby-plugin-page-transitions";
import SEO from "../../components/seo";
import ButtonBlog from "../../components/buttonblog";
import "../../scss/faqpages.scss";


import background from "../../images/foire-aux-questions.jpg";

import html from "../../images/html-logo.png";
import css from "../../images/css-logo.png";
import js from "../../images/js-logo.png";
import php from "../../images/php-logo.png";

import wordpress from "../../images/wordpress-logo.png";
import drupal from "../../images/drupal-logo.png";
import prestashop from "../../images/prestashop-logo.png";
import bootstrap from "../../images/bootstrap-logo.png";
import tailwind from "../../images/tailwind-logo.png";
import gatsby from "../../images/gatsby-logo.png";
import vue from "../../images/vue-logo.png";
import laravel from "../../images/laravel-logo.png";
import symfony from "../../images/symfony-logo.png";

import mysql from "../../images/mysql-logo.png";
import postgresql from "../../images/postgresql-logo.png";

import fbIcon from "../../images/facebook-logo.svg";
import twitterIcon from "../../images/twitter-logo.svg";
import instaIcon from "../../images/instagram-logo.svg";
import bgbloc from "../../images/creation-sites-web-toulouse.jpg";
import BoutonBlog from '../../components/boutonBlog.js';




class TechnoWeb extends Component {

    constructor(props) {
        super(props);
    
    }

render() {
    return (
        <Layout location={this.props.location}>

            <SEO
                title="Quelles sont les technologies utilisées ? | Création site internet à Toulouse (31)"
                description="Nous utilisons différentes technologies web pour répondre aux besoins les plus précis pour des professionnels de tout horizon."
                keywords={[`Création site internet Toulouse`, `Création site internet Agen`, `Agence Web Toulouse`]}
                url="https://linkweb.fr/creation-site-internet-toulouse/technologies-web/"
                nom="Quelles sont les technologies utilisées ?"
                slug="creation-site-internet-toulouse/technologies-web/"
            />
            {/* <PageTransition
                defaultStyle={{
                    transition: 'left 200ms cubic-bezier(0.47, 0, 0.75, 0.72)',
                    transitionDuration: '0.5s',
                    transitionDelay: '0.1s',
                    //left: '100%',
                    position: 'relative',
                    width: '100%',
                    zIndex: 20,
                    minHeight: '100vh',
                }}
                transitionStyles={{
                    entering: { left: '-100%' },
                    entered: { left: '0' },
                    exiting: { left: '-100%' },

                }}
                transitionTime={200}
            > */}
                <section className="w-full bloctitrespages mx-auto pb-0 px-4 pt-6 bg-blackflex flex-col justify-center items-center" style={{background:'#00000094 url(' + background +')', backgroundBlendMode:'multiply', backgroundSize:'cover', backgroundPosition:'center', height:'450px'}}>
                    <h2 data-aos="fade-down" data-aos-delay="500" className="century text-5xl sm:text-6xl text-center text-white mt-64">
                    Quelles sont les technologies utilisées ?
                    </h2>
                    <Link to="/creation-site-internet-toulouse/"><h1 className="century text-xl sm:text-2xl text-center text-white hover:text-bleu" data-aos="fade-up" data-aos-delay="500">Création de site internet à Toulouse</h1></Link>
                    <br/>
                    {/* Réseaux Sociaux */}
                    <section className="w-full flex flex-row justify-center text-white ">
                            <div className="w-1/3 md:w-1/6 lg:w-1/12 flex flex-row">
                                <div className="w-1/3 block mx-auto" data-aos="fade-left">
                                    <a href="https://www.facebook.com/AgenceWebLinkweb" target="blank" rel="noreferrer noopener" className="flex justify-center no-underline text-white">
                                    <img 
                                    src={ fbIcon }
                                    alt="Réseaux sociaux"
                                    className="w-6 fill-current"
                                    />
                                    </a>
                                </div>
                                <div className="w-1/3 block, mx-auto" data-aos="fade-up">
                                    <a href="https://twitter.com/AgenceLinkweb" target="blank" rel="noreferrer noopener" className="flex justify-center no-underline text-white">
                                    <img 
                                    src={ twitterIcon }
                                    alt="Réseaux sociaux"
                                    className="w-6 fill-current"
                                    />
                                    </a>
                                </div>
                                <div className="w-1/3 block, mx-auto" data-aos="fade-right">
                                    <a href="https://www.instagram.com/linkweb_47/" target="blank" rel="noreferrer noopener" className="flex justify-center no-underline text-white">
                                    <img 
                                    src={ instaIcon }
                                    alt="Réseaux sociaux"
                                    className="w-6 fill-current"
                                    style={{maxHeight:'50px', minWidth:'15px'}}
                                    />
                                    </a>
                                </div>
                            </div>
                    </section>
                    {/* FIN Réseaux Sociaux */}
                </section>
                
                <section className="w-full  flex flex-col xl:flex-row flex justify-center">
                    <div className="bloctextpages w-full lg:w-1/2 flex flex-col justify-center century text-justify">
                        
                        <p style={{marginBottom:'50px'}}><Link to="/faq/" className="text-bleu hover:text-black">Foire aux questions</Link> <span className="text-bleu">></span> Questions techniques <span className="text-bleu">></span> Développement du site <span className="text-bleu">></span> Quelles sont les technologies Web utilisées et maîtrisées ?</p>
                        <h3>Créer des sites web fait partie de notre quotidien. Toutefois, saviez-vous qu’il existe plusieurs façon de réaliser la création de votre site internet ?</h3>
                        <br/>
                        <p>La <a href="https://www.data-projekt.fr/agence-web/developpement/creation-site-web/" target="_blank" rel="noopener noreferer" className="text-bleu hover:text-black font-bold"><strong>création de site internet</strong></a> est une discipline qui fait appel à une certaine technicité et donc à des compétences spécifiques. Pour <strong>créer un site web</strong> parfaitement fonctionnel, en <strong>HTML/CSS</strong>, comme sur <strong>Wordpress</strong>, il est important de maîtriser les différentes technologies existantes. En effet, ceci est indispensable pour effectuer des choix selon le <strong>type de site</strong> à créer, mais également les besoins du client et la teneur du <strong>projet web</strong> dans sa globalité.</p>
                        
                        <p>Le travail préparatoire du <strong>développeur</strong> va être double. Non seulement, il va devoir effectuer une étude du <strong>besoin du client</strong> en réunissant l’ensemble des paramètres qui influeront sur sa prise de décision : <strong>type de site web</strong>, <strong>visuel souhaité</strong>, <strong>arborescence SEO</strong> ou non, <strong>parcours client</strong> et surtout service ou produit à mettre en valeur.</p>
                        
                        <p>Une fois l’ensemble des éléments réunis, le développeur va déterminer une <strong>solution adaptée et fonctionnelle</strong> en identifiant les outils qui lui permettront d’arriver à ses fins. Pour un <strong>site web créé via Wordpress</strong>, les considérations ne seront pas moindres. Sélectionner les bonnes extensions, faire le choix d’un thème modulable et rendre la solution parfaitement fonctionnelle sont autant d’enjeux qui incombent dès la conception du <strong>projet web</strong> et plus particulièrement dès la <strong>création du site internet</strong>.</p>
                        
                        <p>De la <strong>création de site internet</strong> « en dur » ou bien via l’utilisation d’applications de différents types, les <strong>développeurs web</strong> de notre <Link to="/" className="font-bold text-bleu hover:text-black">agence web à Agen</Link> réalisent des arbitrages pour choisir la solution la mieux adaptée en fonction de <strong>votre projet web</strong>.</p>

                        <p>En effet, la solution de <strong>développement web</strong> la plus adaptée sera déterminée en fonction de différents facteurs. Parmi eux, nous pouvons citer le <strong>développement de fonctionnalités</strong> précises, le <strong>référencement naturel</strong> ou encore le besoin d'accèder à une administration par exemple.</p>

                        <p>Pour bien comprendre ce qu’incombe à l’utilisation d’une <strong>technologie web</strong> plutôt qu’une autre dans la <Link to="/creation-site-internet-toulouse/" className="text-bleu hover:text-black font-bold">création de site internet à Toulouse</Link>, il va être important de bien les définir.</p>
                        <br/>
                        <h2>Qu'est-ce qu'un site web ?</h2>
                        <br/>
                        <p>Le <strong>site internet</strong> correspond à un <strong>ensemble de pages stockées sur un serveur web</strong> et accessibles depuis un <strong>navigateur web</strong>. Il nécessite la mise en place d'un <strong>hébergement web</strong> pour être fonctionnel. Sur le principe d’un répertoire sur un ordinateur, le <strong>site web</strong> se compose de répertoires et sous répertoires contenant des ressources telles que les <strong>pages web et bien d'autres </strong>types de fichiers.</p>
                        <p>Ces dernières sont accessibles via un <strong>chemin URL</strong> en saisie direct, soit par l’ajout de <strong>liens hypertextes</strong> entre les pages elles-mêmes. Ce <strong>chemin URL</strong> est déterminé par le <strong>nom de domaine du site web</strong> qui correspond à son <strong>adresse digitale</strong>.</p>
                        <p>Une <strong>page web</strong> désigne un fichier de type HTML permettant d’afficher du contenu balisé par du <strong>code HTML</strong>. Par la suite, le <strong>contenu du site web</strong> va pouvoir être stylisé et animé comme nous le verrons par la suite.</p>
                        <br/>
                        <h3 className="text-darkgrey" style={{fontSize:'23px'}}>De quoi se compose un site internet ?</h3>
                        <br/>
                        <p>En fonction de sa finalité, le <strong>site internet</strong> va être composé de façon différente. Un <strong>site internet vitrine</strong> ne répond pas aux mêmes besoins qu’un <strong>site internet e-commerce</strong>. Toutefois, il existe une base commune à l’ensemble des <strong>sites internet</strong>.</p>

                        <p>Comme nous venons de le voir, le <strong>site internet</strong> va être principalement composé de pages, à savoir de <strong>fichiers HTML</strong>. À la base s’y trouve un <strong>index</strong> qui n’est autre que la <strong>page d’accueil du site</strong>.</p> 

                        <p>C’est cette page qui va devoir centraliser un certain nombre d’informations, mais également des <strong>liens hypertextes vers les autres pages web</strong>. La <strong>page d’accueil d’un site</strong> est généralement considérée comme la plus importante.</p>

                        <p>Le contenu (hors contenu textuel) hébergé sur les pages va faire appel à des ressources. Ces ressources vont également être <strong>stockées sur le serveur</strong> afin d’être accessibles lorsqu’elles seront mobilisées. En revanche, ces ressources ne sont pas toujours du même type.</p> 

                        <p>En effet, le <strong>site internet</strong> est en mesure d’héberger bon nombre de contenus : <strong>fichiers images</strong>, <strong>documents pdf</strong>, <strong>dessins vectoriels SVG</strong>, <strong>documents audios mp3</strong>, etc.</p> 

                        <p>Par ailleurs, le <strong>site web</strong> héberge également des ressources destinées à <strong>animer et styliser le contenu</strong> présent sur les <strong>pages web</strong>. Ces ressources vont dépendre de différents langages. Pour utiliser ces <strong>langages</strong>, il va falloir créer des ressources qui contiendront des règles d’utilisation.</p> 

                        <p>À ce niveau, il s’agit de <strong>ressources CSS</strong> pour modifier le style d’une page et de ces différents éléments, ainsi que de ressources <strong>JavaScript</strong> pour <strong>animer les éléments de la page</strong>.</p>
                        <br/>
                        <h3 className="text-darkgrey" style={{fontSize:'23px'}}>Quels langages utiliser pour développer un site internet ?</h3>
                        <br/>
                        <p>Développer les différentes composantes d’un <strong>site web</strong> va demander la mobilisation de différents <strong>langages informatiques</strong>. Que cela concerne la stylisation, l’animation, la <strong>structuration du contenu</strong>, ou encore la <strong>gestion des interactions avec l’utilisateur</strong>, ce sont des langages de différents types qui vont permettre de <strong>développer un site web</strong> dans sa globalité.</p>
                        <br/>
                        <h4>Les principaux langages utilisés sur un site web</h4>
                        <br/>
                        <section className="flex justify-center flex-wrap flex-col sm:flex-row">
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="langages">
                                    <div className="flex flex-row">
                                        <h4 style={{margin:'10px'}}>HTML (Hypertext Markup Language)</h4>
                                        <img src={ html } className="-mt-24" alt="HTML"/>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de langage :</span> balisage</h5> 
                                    <h5><span className="font-bold">Intérêt :</span> structuration du contenu</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#212121" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#212121" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#212121" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p>Le <strong>HTML</strong> est un <strong>langage de balisage</strong> qui permet d’introduire du contenu textuel ou visuel au sein d’une page. Il est également destiné à <strong>structurer ce contenu</strong> grâce aux diverses balises qu’il propose.</p>
                                </div>
                                
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="langages">
                                    <div className="flex flex-row">
                                        <h4 style={{margin:'10px'}}>CSS (Cascading Style Sheets)</h4>
                                        <img src={ css } className="-mt-24" alt="CSS"/>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de langage :</span> feuille de style</h5> 
                                    <h5><span className="font-bold">Intérêt :</span> mise en forme du contenu</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#212121" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p>Le <strong>CSS</strong> est un langage qui concerne la <strong>mise en forme de documents HTML</strong>. Il permet de structurer visuellement les différentes parties d’une <strong>page web</strong>. Le CSS est un langage complémentaire au <strong>langage HTML</strong>.</p>
                                </div>
                                
                            </article>
                            
                        <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="langages">
                                    <div className="flex flex-row">
                                        <h4 style={{margin:'10px'}}>JS (JavaScript)</h4>
                                        <img src={ js } className="-mt-24 mx-auto flex justify-end" alt="JavaScript"/>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de langage :</span> scripts</h5> 
                                    <h5><span className="font-bold">Intérêt :</span> animation du contenu et interactions</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p>Le <strong>JavaScript</strong> est un <strong>langage de programmation</strong> ou <strong>langage de scripts</strong>. Il permet notamment l’animation des <strong>éléments présents sur une page web</strong>. Le JS est également utilisé pour <strong>gérer l’interaction entre des éléments et l’utilisateur</strong>.</p>
                                </div>
                                
                            </article>
                        <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="langages">
                                    <div className="flex flex-row">
                                        <h4 style={{margin:'10px'}}>PHP (Hypertext Preprocessor)</h4>
                                        <img src={ php } className="-mt-24" alt="PHP"/>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de langage :</span> scripts</h5> 
                                    <h5><span className="font-bold">Intérêt :</span> contenus dynamiques et interactions</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p>Le <strong>PHP</strong> est, comme le <strong>JavaScript</strong>, un <strong>langage de scripts</strong> qui permet de rendre une <strong>page web dynamique</strong>. Le PHP est un langage qui ne renvoie que le <strong>résultat du script au client</strong>, il est parfois utilisé pour afficher une page différemment en fonction de l’user-agent qui la consulte.</p>
                                </div>
                                
                            </article>
                        </section>
                        <br/>
                        <hr style={{width:'100%'}}/>
                        <br/>
                        <h2>Quelles sont les différentes technologies web pour créer un site ?</h2>
                        <br/>
                        <p>Ces langages vont être essentiels dans la <strong><Link to="/creation-de-site-internet-a-agen/" className="text-bleu font-bold hover:text-black">création de sites internet à Agen</Link></strong>. Parallèlement, il faut savoir que différentes <strong>applications web</strong> ont été développées pour permettre de faciliter l’utilisation de ces différents ressources.</p> 

                        <p>Parmi elles, nous pouvons citer les <strong>CMS (systèmes de gestion de contenus)</strong>, les <strong>frameworks</strong> (ou bibliothèques), mais aussi les <strong>gestionnaires de bases de données</strong>.</p>

                        <p>Ces différents outils ont pour objectif d’<strong>optimiser le traitement des différents langages de programmation sur un site internet</strong> afin de gagner en productivité notamment, mais également de démocratiser la pratique.</p>
                        <br/>
                        <h3 className="text-darkgrey" style={{fontSize:'23px'}}>Content Management System</h3>
                        <br/>
                        <p>Le <strong>CMS (Content Management System)</strong> ou <strong>système de gestion de contenu</strong> désigne une application permettant la <strong>création de site internet</strong> sans toucher au code. Le <strong>CMS</strong> est un outil particulièrement utilisé lorsqu’il est question de <strong>mettre à jour un site web très fréquemment</strong> (publication d’un <strong>article de blog</strong>, <strong>ajout de produits sur un site e-commerce</strong>, etc.).</p>
                        <p>Le <strong>CMS</strong> s’illustre par une <strong>interface d’administration</strong> permettant de faciliter certaines tâches, mais également d’autoriser plusieurs collaborateurs à se connecter simultanément. Le CMS s’inscrit dans un écosystème qui favorise l’utilisation d’<strong>extensions</strong> et autres widgets extérieurs, pouvant être incorporés au <strong>site web</strong>.</p>
                        <p>Nous utilisons des CMS qui font <strong>référence sur le web</strong> depuis des dizaines d’années désormais.</p>
                        <br/>
                        <section className="flex flex-wrap flex-col sm:flex-row">
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ wordpress } className="-mt-24 mx-auto block" alt="Création site internet Wordpress"/>
                                        <h4 style={{margin:'10px'}}>Wordpress</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de logiciel :</span> open-source</h5>
                                    <h5><span className="font-bold">Type de sites web créés :</span> site vitrine, site e-commerce, site catalogue, blog</h5>
                                    <h5><span className="font-bold">Langage de programmation :</span> PHP</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#212121" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Wordpress</strong> est un <strong>CMS</strong> écrit en <strong>langage PHP</strong> et basé sur l’utilisation du <strong>gestionnaire de base de données MySQL</strong>. Il est le <strong>CMS le plus populaire dans le monde</strong> entier. Il permet de <strong>créer des sites internet</strong> de tous types grâce à la variété de fonctionnalités qu’il propose.</p>
                                </div>
                                
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ drupal } className="-mt-24 mx-auto block" alt="Création site internet Drupal"/>
                                        <h4 style={{margin:'10px'}}>Drupal</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de logiciel :</span> open-source</h5>
                                    <h5><span className="font-bold">Type de sites web créés :</span> site vitrine, site e-commerce, site catalogue, blog</h5>
                                    <h5><span className="font-bold">Langage de programmation :</span> PHP</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Drupal</strong> est un <strong>CMS</strong> également basé sur le <strong>langage PHP</strong>. Il permet l’ajout de fonctionnalités externes appelées « <strong>modules</strong> » contre <strong>« plugins » chez Wordpress</strong>. Drupal est connu pour la richesse des possibilités qu’il offre aux <strong>développeurs</strong> : en termes de de gestion des droits d’administration par exemple.</p>
                                </div>
                                
                            </article>
                            
                        <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm"  style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ prestashop } className="-mt-24 mx-auto block" alt="Créer une boutique en ligne Prestashop"/>
                                        <h4 style={{margin:'10px'}}>Prestashop</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de logiciel :</span> open-source</h5> 
                                    <h5><span className="font-bold">Type de sites web créés :</span> site e-commerce.</h5> 
                                    <h5><span className="font-bold">Langage de programmation :</span> PHP</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Prestashop</strong> est un CMS destiné principalement à la <strong>création de boutiques en ligne</strong>. Basé sur le <strong>langage de programmation PHP</strong> il s’appuie également sur <strong>MySQL</strong> en ce qui concerne la <strong>gestion des bases de données</strong>. Prestashop propose des « <strong>addons</strong> » à ses utilisateurs quand on parle de <strong>« plugins » Wordpress</strong> et <strong>« modules » Drupal</strong>.</p>
                                </div>
                                
                            </article>
                        </section>
                        <br/>
                        <hr style={{width:'100%'}}/>
                        <br/>
                        <h3 className="text-darkgrey" style={{fontSize:'23px'}}>Les Frameworks</h3>
                        <br/>
                        <p>Les <strong>frameworks</strong> sont des <strong>infrastructures logicielles</strong> qui vont être mobilisables lors du <strong>développement d’applications web</strong>. Elles sont caractérisées par la mise en place d’une <strong>bibliothèque de ressources open source</strong> visant à standardiser et à simplifier l’utilisation des <strong>différents langages de développement web</strong>.</p>
                        <p>Il existe donc des <strong>frameworks</strong> qui peuvent s’appliquer pour le <strong>CSS (Bootstrap, Tailwind, etc.)</strong>, le <strong>JavaScript (Vue JS, AngularJS)</strong>, le <strong>PHP (Symfony, Laravel)</strong> ou encore <strong>Python (Django)</strong>. Pour qu’ils soient applicables sur un <strong>site web</strong>, leurs ressources doivent être importées dans l’application. Les <strong>frameworks</strong> sont destinés à augmenter la productivité et <strong>réduire les délais de conception d’un site web</strong>. De plus, les objets proposés sont directement utilisables car ils sont <strong>responsive design</strong>.</p>
                        <p>En somme, ils ne proposent pas d’interface à proprement parler. En revanche, les ressources qu’ils présentent sont accessibles au sein de leur documentation présente sur leurs sites officiels. Nous utilisons des frameworks opérationnels pour <strong>créer nos sites web</strong>.</p>
                    <br/>
                    <section className="flex flex-wrap flex-col sm:flex-row">
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ bootstrap } className="-mt-24 mx-auto block" alt="Création site internet Bootstrap"/>
                                        <h4 style={{margin:'10px'}}>Bootstrap</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de framework :</span> open-source</h5>
                                    <h5><span className="font-bold">Ressources utilisées :</span> CSS, HTML, JS</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#212121" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Bootstrap</strong> est une <strong>librairie front-end</strong> qui regroupe des éléments prédéfinis visant à faciliter leur utilisation. La librairie proposée par <strong>Bootstrap</strong> est basée sur des <strong>ressources CSS, HTML</strong>, mais également des éléments <strong>JavaScript</strong>. Ces éléments sont déterminés à partir de règles appliquées de façon globale afin de réduire le temps de <strong>production d’un site internet</strong>.</p>
                                </div>
                                
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ tailwind } className="-mt-24 mx-auto block" alt="Création site internet Tailwind"/>
                                        <h4 style={{margin:'10px'}}>Tailwind CSS</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de framework :</span> open-source</h5>
                                    <h5><span className="font-bold">Ressources utilisées :</span> CSS</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#212121" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#212121" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Tailwind CSS</strong> est un <strong>framework</strong> qui s’applique principalement pour les <strong>feuilles de style</strong>. Il présente une <strong>bibliothèque de classes CSS</strong> utilisables de manière particulièrement simple. Il est une alternative à Bootstrap qui a pour particularité de proposer en partie des éléments prédéfinis et prêts à l'emploi (<strong>carousel</strong>, <strong>menu de navigation</strong>, etc.). <strong>Tailwind</strong> présente l’avantage de <strong>développer des pages web</strong> de façon extrêmement rapide.</p>
                                </div>
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ gatsby } className="-mt-24 mx-auto block" alt="Création site internet Gatsby JS"/>
                                        <h4 style={{margin:'10px'}}>Gatsby JS</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de framework :</span> open-source</h5>
                                    <h5><span className="font-bold">Ressources utilisées :</span> React JS</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#212121" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Gatsby JS</strong> est un <strong>framework JavaScript</strong> qui mobilise la bibliothèque <strong>React JS</strong> dans la <strong>conception d’interface web statiques</strong>. En ce sens, <strong>Gatsby JS</strong> est un <strong>générateur de sites web statiques</strong> prêts à être propulsés sur un <strong>serveur web</strong>. Ce <strong>framework</strong> est particulièrement adapté pour les <strong>sites web statiques</strong> dont la fréquence de <strong>mise à jour</strong> reste faible.</p>
                                </div>
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ vue } className="-mt-24 mx-auto block" alt="Création site internet Vue JS"/>
                                        <h4 style={{margin:'10px'}}>Vue JS</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de framework :</span> open-source</h5>
                                    <h5><span className="font-bold">Ressources utilisées :</span> JavaScript</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p>Comme <strong>Gatsby JS</strong>, <strong>Vue JS</strong> est un <strong>framework JavaScript</strong> destiné à la création de <strong>Single Page Application</strong> ou <strong>application web monopage</strong>. Ce <strong>type de framework</strong> a pour objectif de proposer une <strong>expérience utilisateur optimale</strong> à travers une rapidité de navigation améliorée et tout en permettant une lecture adaptée pour les <strong>robots des moteurs de recherche</strong> grâce au <strong>rendu côté serveur (SSR)</strong>. </p>
                                </div>
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ laravel } className="-mt-24 mx-auto block" alt="Laravel"/>
                                        <h4 style={{margin:'10px'}}>Laravel</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de framework :</span> open-source</h5>
                                    <h5><span className="font-bold">Ressources utilisées :</span> PHP</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Laravel</strong> est un <strong>framework PHP</strong> permettant la <strong>création d’applications web</strong> de part l’utilisation du <strong>langage PHP</strong>. Il propose des <strong>bibliothèques d’objets</strong> destinées à faciliter l’<strong>implémentation de fonctionnalités</strong> pour les développeurs sur les <strong>sites internet</strong>. De plus, il permet de <strong>gérer des bases de données</strong> pour rendre un <strong>site internet dynamique</strong>.</p>
                                </div>
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="frcm" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ symfony } className="-mt-24 mx-auto block" alt="Création site internet Wordpress"/>
                                        <h4 style={{margin:'10px'}}>Symfony</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de framework :</span> open-source</h5>
                                    <h5><span className="font-bold">Ressources utilisées :</span> PHP</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>Symfony</strong> est un <strong>framework PHP</strong> qui propose des objets adaptables pour faciliter l’<strong>implémentation de fonctionnalités sur un site internet</strong>. Écrit en <strong>PHP</strong>, il est considéré comme un <strong>framework MVC (modèle-vue-contrôle)</strong>. Dans ce cadre, les trois responsabilités engagées dans le modèle interagissent entre elles.</p>
                                </div>
                            </article>
                        </section>
                        <br/>
                        <hr style={{width:'100%'}}/>
                        <br/>
                        <h3 className="text-darkgrey" style={{fontSize:'23px'}}>Les gestionnaires de base de données</h3>
                        <br/>
                        <p>Enfin, les <strong>gestionnaires de base de données</strong> ne sont pas moins importants dans le processus de <strong>création de site internet</strong>. En effet, les <strong>systèmes de gestion de base de données</strong> permettent le <strong>stockage</strong>, la <strong>gestion</strong> et la <strong>manipulation de données</strong>. Ces systèmes sont également chargés de <strong>garantir la sécurité et la confidentialité des données traitées</strong>.</p>
                        <p>Les <strong>gestionnaires de bases de données</strong> sont composés de <strong>moteur</strong>, <strong>catalogue</strong>, <strong>processeur de requête</strong> ainsi que de <strong>langage de commande</strong>. Leur fonctionnement permet une <strong>gestion des données</strong> qui va être plus simple et rapide. Dans nos processus de <strong>création de sites internet</strong>, nous utilisons des <strong>gestionnaires de bases de données reconnus</strong>.</p>
                        <section className="flex flex-wrap flex-col justify-center sm:flex-row">
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="sbdd" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ mysql } className="-mt-24 mx-auto block" alt="MySQL"/>
                                        <h4 style={{margin:'10px'}}>MySQL</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de logiciel :</span> open-source</h5>
                                    <h5><span className="font-bold">Type de de gestionnaire :</span> système de gestion de base de données relationnelles</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>MySQL</strong> est l’un des <strong>systèmes de gestion de bases de données</strong> les plus populaires et les plus utilisés dans le monde. Concrètement, il est particulièrement utile pour <strong>enregistrer des données pour des sites communautaires</strong>, mais pas seulement. En effet, il va permettre de <strong>traiter des données</strong> de façon optimale et rapide (<strong>commentaires postés sous un article de blog</strong>, utilisateurs inscrits à un service, etc.).</p>
                                </div>
                            </article>
                            <article className="w-full md:w-1/2 lg:w-1/2 mt-5 xl:w-1/3 century w-1/2 mx-0 md:px-2 pb-4 flex flex-col align-center">
                                <div className="sbdd" style={{minHeight:'400px'}}>
                                    <div className="flex flex-col">
                                        <img src={ postgresql } className="-mt-24 mx-auto block" alt="PostgreSQL"/>
                                        <h4 style={{margin:'10px'}}>PostgreSQL</h4>
                                    </div>
                                    <br/>
                                    <h5><span className="font-bold">Type de logiciel :</span> open-source</h5>
                                    <h5><span className="font-bold">Type de de gestionnaire :</span> système de gestion de base de données relationnelle-objet</h5>
                                    <div className="flex flex-row">
                                        <h5 className="font-bold">Difficulté :</h5>
                                        <svg  width="80px" viewBox="0 0 100 125" style={{marginTop:'-31px'}}>
                                            <path fill="#ffffff" d="M19.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.8-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C19.6,47.8,19.5,47.7,19.3,47.7z"/>
                                            <path fill="#ffffff" d="M38.8,47.7l-5.4-0.8L31,42c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8L25,57.8c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.2,0.1,0.4,0.1,0.5,0  c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C39.2,47.8,39,47.7,38.8,47.7z"/>
                                            <path fill="#ffffff" d="M58.3,47.7l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3l-2.4,4.9l-5.4,0.8c-0.2,0-0.3,0.2-0.4,0.3  c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5c0.1,0,0.1,0.1,0.2,0.1  c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8c0.1-0.1,0.2-0.3,0.1-0.5C58.6,47.8,58.5,47.7,58.3,47.7z"/>
                                            <path fill="#ffffff" d="M78.2,48c-0.1-0.2-0.2-0.3-0.4-0.3l-5.4-0.8l-2.4-4.9c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-0.2,0.1-0.2,0.2l-2.4,4.9l-5.4,0.8  c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.2,0,0.4,0.1,0.5l3.9,3.8l-0.9,5.4c0,0.2,0,0.4,0.2,0.5c0.2,0.1,0.4,0.1,0.5,0l4.9-2.5l4.9,2.5  c0.2,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.3,0.2-0.5l-0.9-5.4l3.9-3.8C78.2,48.4,78.2,48.2,78.2,48z"/>
                                            <path fill="#ffffff" d="M95.1,58.3c-0.1,0-0.2,0-0.2-0.1l-4.8-2.6l-4.9,2.6c-0.2,0.1-0.5,0-0.7-0.2c-0.1-0.1-0.1-0.2,0-0.3l0.9-5.4l-4-3.8  c-0.2-0.2-0.2-0.5,0-0.7c0.1-0.1,0.2-0.1,0.3-0.1l5.4-0.8l2.4-4.9c0.1-0.2,0.4-0.3,0.7-0.2c0.1,0,0.2,0.1,0.2,0.2l2.4,4.9l5.4,0.8  c0.3,0,0.4,0.3,0.4,0.6c0,0.1-0.1,0.2-0.1,0.3l-3.9,3.8l0.9,5.4C95.6,58,95.5,58.3,95.1,58.3C95.2,58.3,95.1,58.3,95.1,58.3z"/>
                                        </svg>
                                    </div>
                                    <br/>
                                    <p><strong>PostgreSQL</strong> est un <strong>système de gestion de bases de données orienté objet</strong>. Il est implémenté sur un <strong>unique serveur</strong>. Son potentiel d’évolution vis-à-vis d’un outil comme <strong>MySQL</strong> est réduit. Toutefois, <strong>PostegreSQL</strong> permet aux utilisateurs le <strong>traitement de leurs données</strong> sans avoir à s’engager dans le <strong>code source</strong>.</p>
                                </div>
                            </article>
                        </section>
                        <br/>
                        <hr style={{width:'100%'}}/>
                        <br/>
                        <h2>Quelles solutions pour quels types de sites internet ?</h2>
                        <br/>
                        <p>En fonction de la <strong>solution web</strong> à développer, certaines technologies seront plus adaptées à l’utilisation que d’autres. En effet, les <strong>CMS</strong> et <strong>frameworks</strong> procurent un gain particulièrement important en termes de productivité. L’administration (ou <strong>éditeur du site</strong>) proposée par les <strong>CMS</strong> peut également être une bonne alternative pour les <strong>sites web administrables</strong>.</p>

                        <p>Toutefois, quelque soit l’outil utilisé, il sera toujours possible de réaliser des adaptations pour arriver à ses fins. En ce sens, la <strong>technologie web</strong> n’est qu’un outil. Si le codage « en dur » (<strong>HTML/CSS</strong>), reste possible, les <strong>CMS</strong> seront également modulables pour mettre en place des fonctionnalités particulières.</p>

                        <p>Ces adaptations seront possibles notamment dans le cadre de la <strong>création de sites internet sur-mesure</strong>. Les alternatives sont nombreuses pour <strong>créer un site internet professionnel</strong>, tout en développant un <strong>web design intuitif</strong> et une <strong>stratégie SEO</strong> adaptée <strong>à votre image</strong> et à vos besoins et cela quel que soit le <strong>type de site internet</strong>.</p>
                        </div>
                        <div className="w-full mx-auto lg:w-1/2 xl:w-1/4 px-0 md:px-24 lg:px-10 mb-32 text-justify font-normal flex flex-col justify-start items-center lg:items-start" >
                        <div className="bloccontactsticky w-full lg:w-full flex flex-col justify-center items-center century text-justify flex" style={{background:'#3c3c3cfa url(' + bgbloc +')', backgroundBlendMode:'overlay', backgroundSize:'cover', backgroundPosition:'center'}}>
                                    <h2>Vous souhaitez mettre en place un projet web ?</h2>
                                    <br/>
                                    <h3>Linkweb met en place votre projet de création de site web.</h3>
                                    <br/>
                                    <div className="w-1/2 flex flex-row justify-center items-center">
                                    <br/>
                                        <BoutonBlog  url="/contact-agence-web-toulouse/" text="Contactez-nous" position="center" />
                                    </div>
                        </div>
                    </div>
                </section>

                
                
            {/* </PageTransition> */}
        </Layout>
    );
}
};



export default TechnoWeb;
